<template>
  <v-container fluid>
    <v-row>
      <v-col v-for="client in clients" :key="client.id" cols="12" md="6">
        <v-btn
          height="90"
          block
          class="title text-wrap"
          color="primary"
          :to="{ name: 'detailClient', params: { clientId: client.code,year: '2022' } }"
        >
          {{ client.name }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Client",
  data: () => ({
    clients: [],
  }),
  mounted() {
    this.getClients();
  },
  methods: {
    getClients() {
      this.$store.dispatch("working", true);
      this.$http
        .get("catalogs/getClientsByUser")
        .then((res) => {
          this.clients = res.data;
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            if (error.response.data.msg) {
              this.$toast.error(error.response.data.msg);
            } else {
              this.$toast.error("Ocurrio un error inesperado.");
            }
          } else {
            this.$toast.error("Ocurrio un error inesperado.");
          }
        })
        .finally(() => {
          this.$store.dispatch("working", false);
        });
    },
  },
};
</script>

<style scoped></style>
